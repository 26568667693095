.walletCard {
	margin: 1rem;
	border: 1px solid black;
	border-radius: 5px;
}

.customButton {
  background-color: #4e4a4c;
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Regular', sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #F3B54F;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-width: thin;
	border-color: white;
}
