.wrapper {
  @apply relative;
  min-width: 150px;
  .btn {
    @apply grid w-full items-center overflow-hidden px-2 py-1 cursor-pointer font-bold font-text text-white;
    grid-template-columns: 1fr max-content;
    gap: 4px;
background-color: #8C838A;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
   
    .value {
      max-width: calc(100%);
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .chevron {
      @apply w-4 h-4 leading-none transition-transform;
      transform-origin: 25% 50%;
      &.open{
        @apply rotate-90 ;
       
      }
    }
  }
  .dropdown {
    @apply absolute left-0 opacity-0 pointer-events-none transition-all z-10 flex flex-col gap-2 px-4 py-3  text-white  font-text overflow-y-auto w-full;
    top: calc(100% + 12px);

    max-width: 160px;
    max-height: 240px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(80,74,76,0.8);
    &.open {
      @apply opacity-100 pointer-events-auto;
    }
  }
}
