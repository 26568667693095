.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header{
  height: 85px;
  background: rgb(243,181,79);
  background: linear-gradient(180deg, rgba(243,181,79,1) 33.3%, rgba(226,130,59,1) 33.3%, rgba(226,130,59,1) 66.6%, rgba(210,82,60,1) 66.66%, rgba(210,82,60,1) 100%);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #EAE9E8; /* Change the background color and opacity as needed */
  z-index: 9999; /* Adjust the z-index value to ensure it appears on top */
}

.loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid rgba(210,82,60,1); /* Blue top border */
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite; /* Rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.svg-image {
  fill: #f3f3f3; /* Replace with your desired color */
}

.rec.rec-arrow {
    border-radius: 50%;
    background-color: #D2523C;
    color: #F8F2E5;
    margin-left: 20px; /* Adjust the value as needed */
    margin-right: 20px;
}

.rec.rec-arrow:hover:not(:disabled)  {
    border-radius: 50%;
    background-color: #F3B54F;
    color: #F8F2E5;
    margin-left: 20px; /* Adjust the value as needed */
    margin-right: 20px;
}


.rec.rec-arrow:disabled {
    border-radius: 50%;
    background-color: gray;
    color: white;
    margin-left: 20px; /* Adjust the value as needed */
    margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  .rec.rec-arrow {
      border-radius: 50%;
      background-color: #F3B54F;
      color: #F8F2E5;
      margin-left: 0; /* Adjust the value as needed */
      margin-right: 0;
      width: 1.5rem !important;
      height: 1.5rem !important;
      min-width: 1.5rem !important;
      line-height: 1.5rem !important;
  }

  .rec.rec-arrow:hover:not(:disabled)  {
      border-radius: 50%;
      background-color: #F3B54F;
      color: #F8F2E5;
      margin-left: 0; /* Adjust the value as needed */
      margin-right: 0;
      width: 1.5rem !important;
      height: 1.5rem !important;
      min-width: 1.5rem !important;
      line-height: 1.5rem !important;
  }


  .rec.rec-arrow:disabled {
      border-radius: 50%;
      background-color: gray;
      margin-left: 0; /* Adjust the value as needed */
      margin-right: 0;
      width: 1.5rem !important;
      height: 1.5rem !important;
      min-width: 1.5rem !important;
      line-height: 1.5rem !important;
  }
}

/* hide disabled buttons */

/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
}

.dynamic-shadow-dom{
  --dynamic-connect-button-background: #504A4C;
  --dynamic-hover: #BAB5B9;
  --dynamic-font-family-primary: "Gilroy-Regular", sans-serif;
  --dynamic-text-primary: #F3B54F;
  --dynamic-connect-button-shadow: 0px 5px 2px rgba(0, 0, 0, 0.1);
  --dynamic-connect-button-border: 1px solid #FFFFFF;
  --dynamic-connect-button-border-hover: 1px solid #FFFFFF;
  --dynamic-text-size-button-primary: 14px;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.emptyMaxieHover:hover{
  content: url('../src/img/maxinEmptyHover.png');
}

.animate-move {
    animation: shrinkAnimation 5s forwards;
    z-index: 9998; /* Keep it above the tint */
}

@keyframes move-up {
    from {transform: translateY(0)}
    to {transform: translateY(-10vh)} /* Move it out of view */
}


.flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0;
  transition: opacity 1s;
  animation: fadein 1s forwards;
  z-index: 9999;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-20px); /* Change the negative value to move the element from top to bottom */
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInFromTop {
  animation: 2s ease-out 0s 1 slideInFromTop;
}

.grayscale-children > * {
  @apply filter grayscale;
}

.grayscale-children > .sold-out-button {
  filter: none !important;
}

@keyframes shrinkAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9); /* Change 0.8 to any desired scale factor */
  }
}

@keyframes rattle {
  0%, 100% { transform: rotate(-5deg); }    /* Starts and ends rotated slightly to the left (downward left) */
  50% { transform: rotate(5deg); }          /* Rotates slightly to the right (downward right) at the halfway point */
}

.rattle-effect {
  animation: rattle 0.5s infinite;
}

.transition-opacity {
  transition: opacity 0.5s ease;
}

.fade-out {
  transition: opacity 1s ease;
  opacity: 0;
}

.fade-in {
  transition: opacity 1s ease;
  opacity: 0;
}

.aspect-ratio-square {
  position: relative;
  width: 100%; /* or set to whatever width you want */
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.aspect-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* Optional: If you want content centered both vertically and horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
